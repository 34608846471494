import { ActionIcon, Button, Text, Group, Header, Title } from "@mantine/core"
import { useTokenActions } from "../context/Tokens"
import { useIsLargeScreen } from "../hooks/screenSize"
import { IconMenu2 } from "@tabler/icons"

const CustomHeader = ({ openDrawer }: { openDrawer: () => void }) => {
  const { clearTokens } = useTokenActions()
  const isLargeScreen = useIsLargeScreen()

  return (
    <Header height={60} p="xs">
      <Group position="apart">
        <Group>
          {!isLargeScreen ? (
            <ActionIcon size="lg" onClick={openDrawer}>
              <IconMenu2 size={24} />
            </ActionIcon>
          ) : null}
          <Title order={2}>Patient Portal</Title>
          <Text weight={500} color="indigo" italic size={18}>
            beta
          </Text>
        </Group>
        <Group>
          <Button variant="outline" onClick={clearTokens}>
            Logout
          </Button>
        </Group>
      </Group>
    </Header>
  )
}

export default CustomHeader
