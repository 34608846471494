import { Route, Routes } from "react-router-dom"
import ConsentForm from "./ConsentForm"
import FinancialLiabilityForm from "./FinancialLiabilityForm"
import FormList from "./FormList"
import HippaForm from "./HippaForm"

const Forms = () => {
  return (
    <Routes>
      <Route path="/" element={<FormList />} />
      <Route path="/consent-form" element={<ConsentForm />} />
      <Route
        path="/financial-liability-form"
        element={<FinancialLiabilityForm />}
      />
      <Route path="/hippa-form" element={<HippaForm />} />
    </Routes>
  )
}
export default Forms
