import { Alert } from "@mantine/core"
import { IconAlertCircle } from "@tabler/icons"

const Payments = () => {
  return (
    <Alert
      icon={<IconAlertCircle size={16} />}
      title="Under Construction"
      color="blue"
    >
      The payments page will allow you to set up a payment method for your
      account. This will allow you to pay for your dental services online.
    </Alert>
  )
}
export default Payments
