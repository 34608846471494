import { Grid } from "@mantine/core"
import type { IFormItem } from "./FormListItem"
import FormListItem from "./FormListItem"

const formItems: IFormItem[] = [
  {
    name: "Consent Form",
    path: "consent-form",
  },
  {
    name: "Financial Liability Form",
    path: "financial-liability-form",
  },
  {
    name: "HIPPA Form",
    path: "hippa-form",
  },
]

const FormList = () => {
  return (
    <Grid>
      {formItems.map((item) => (
        <FormListItem key={item.path} {...item} />
      ))}
    </Grid>
  )
}
export default FormList
