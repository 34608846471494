import { Drawer, Grid, UnstyledButton } from "@mantine/core"
import { useLocation, useNavigate } from "react-router-dom"
import { navbarItems } from "../config/navbar-items"

const MobileNavbar = ({
  drawerOpened,
  closeDrawer,
}: {
  drawerOpened: boolean
  closeDrawer: () => void
}) => {
  const navigate = useNavigate()
  const itemSelected = (path: string) => {
    closeDrawer()
    navigate(path)
  }
  const location = useLocation()
  return (
    <Drawer
      opened={drawerOpened}
      size="xl"
      padding="xl"
      title="Pages"
      onClose={closeDrawer}
    >
      <Grid>
        {navbarItems.map(({ label, path }) => (
          <Grid.Col key={label}>
            <UnstyledButton
              sx={(theme) => ({
                width: "100%",
                display: "block",
                padding: theme.spacing.xs,
                borderRadius: theme.radius.sm,
                backgroundColor:
                  location.pathname === path
                    ? theme.colors.gray[2]
                    : "transparent",
              })}
              onClick={() => itemSelected(path)}
            >
              {label}
            </UnstyledButton>
          </Grid.Col>
        ))}
      </Grid>
    </Drawer>
  )
}
export default MobileNavbar
