export interface ITokens {
  accessToken: string
  idToken: string
}
export const getTokensFromHash = (url: string): ITokens => {
  const idTokenRE = /id_token=(.*?)&/
  const accessTokenRE = /access_token=(.*?)&/
  const extractRE = (re: RegExp) => re.exec(url)?.at(1)
  const idToken = extractRE(idTokenRE)
  const accessToken = extractRE(accessTokenRE)
  if (!idToken || !accessToken) {
    throw new Error("Could not extract tokens from URL")
  }
  return {
    idToken,
    accessToken,
  }
}
