import "./App.css"
import Layout from "./components/Layout"
import { Routes, Route } from "react-router-dom"
import Dashboard from "./components/Dashboard"
import Payments from "./components/Payments"
import Profile from "./components/Profile"
import Forms from "./components/forms/Forms"

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/forms/*" element={<Forms />} />
        <Route path="/payments" element={<Payments />} />
        <Route path="/profile" element={<Profile />} />
      </Routes>
    </Layout>
  )
}

export default App
