import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { cognitoLoginUrl, cognitoLogoutUrl } from "../config/cognitoHostedUI"
import { getTokensFromHash, ITokens } from "../util"

interface ITokenContext {
  tokens: ITokens
  setTokens: (tokens: ITokens | null) => void
}

const TokensContext = createContext<null | ITokenContext>(null)

interface ITokenContextProviderProps {
  children: React.ReactNode
}

export const TokenContextProvider = ({
  children,
}: ITokenContextProviderProps) => {
  const [tokens, setTokens] = useState<null | ITokens>(null)
  const { hash } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    console.log("TokenContextProvider useEffect")
    if (tokens !== null) {
      return
    }

    // Local storage
    const newTokens = localStorage.getItem("tokens")
    if (newTokens) {
      setTokens(JSON.parse(newTokens))
      console.log("Retrieved tokens from local storage")
      return
    }

    // URL
    try {
      const tokensFromUrl = getTokensFromHash(hash)
      setTokens(tokensFromUrl)
      localStorage.setItem("tokens", JSON.stringify(tokensFromUrl))
      console.log("Retrieved tokens from URL")
      navigate("/")
      return
    } catch (e) {
      console.log("No tokens found")
      setTokens(null)
    }
    window.location.assign(cognitoLoginUrl)
  }, [hash, navigate, tokens])

  if (tokens === null) {
    return <div>loading...</div>
  }
  return (
    <TokensContext.Provider value={{ tokens, setTokens }}>
      {children}
    </TokensContext.Provider>
  )
}
export const useTokenActions = () => {
  const tokensContext = useContext(TokensContext)
  if (tokensContext === null) {
    throw new Error("useTokenActions must be used within TokenContextProvider")
  }
  const { tokens, setTokens } = tokensContext

  const clearTokens = useCallback(async () => {
    await localStorage.removeItem("tokens")
    await setTokens(null)
    await window.location.assign(cognitoLogoutUrl)
  }, [setTokens])

  return useMemo(
    () => ({
      tokens,
      clearTokens,
    }),
    [tokens, clearTokens]
  )
}
