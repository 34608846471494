import { Grid, List, Text, TextInput, Title } from "@mantine/core"
import { DatePicker } from "@mantine/dates"

const HippaForm = () => {
  return (
    <Grid>
      <Grid.Col>
        <Title order={4}>
          Patient Consent for the Disclosure of Information and Acknowledgement
          Form (HIPPA)
        </Title>
      </Grid.Col>
      <Grid.Col>
        <List>
          <List.Item>
            <Text>
              Sharing information for the purpose of treatment: You will share
              my information with all members of my treatment team, both within
              this office and with other providers (personal and Institutional)
              in order to provide me or my child with quality care and
              educational/wellness programs specified by my insurance plan. This
              will include communication with our team in verbal and non-verbal
              form such as postcard reminders, recognition boards, sign in
              information, and forms of communication for patient care and
              office visits.
            </Text>
          </List.Item>
          <List.Item>
            <Text>
              Sharing information for purposes of payment: You will share all
              necessary information with mine or my child’s insurer(s),
              governmental entities, and their representatives (including, but
              not limited to benefit determination and utilization review) as
              well as your representatives involved in the billing process
              companies, and in extreme situations, credit bureaus or collection
              agencies.
            </Text>
          </List.Item>
          <List.Item>
            <Text>
              Sharing of information for the purposes of operations: You will
              share all information necessary for ongoing operations of this
              office, including (but not limited to) the credentialing
              processes, peer review, accreditation, and compliance with all
              federal and state laws
            </Text>
          </List.Item>
        </List>
      </Grid.Col>
      <Grid.Col>
        <Text>
          I also understand that by signing this form, I give this office
          permission to leave messages on my answering machine or voicemail or
          with a relative regarding: notification of appointments, messages to
          call the office, test results, and any other information pertaining to
          mine or my child’s healthcare.
        </Text>
      </Grid.Col>
      <Grid.Col>
        <Text>TODO Hippa relationship</Text>
      </Grid.Col>
      <Grid.Col>
        <Text>
          I understand that you will be unable to release ANY information to
          anyone other than the person/persons listed above. I authorize the
          Temporary Guardian, in the event that I cannot be contacted or if any
          urgency dictates, to act in loco parentis for the Child in respect of
          any circumstances, including any accident or illness, which may
          necessitate medical treatment, including surgery, and on my behalf to
          authorize any such treatment or surgery which they, in their sole
          discretion, (which discretion shall not be unreasonably exercised),
          may deem necessary. Medical treatment for the Child may also include
          dental surgery, x-ray, blood transfusion, anesthetic and medication
          provided any such medical treatment is performed by a duly licensed
          practitioner. I hereby accept full liability for all costs incurred
          through such medical treatment for the Child.
        </Text>
      </Grid.Col>
      <Grid.Col>
        <Text>My consent is freely given.</Text>
      </Grid.Col>
      <Grid.Col span={4}>
        <TextInput label="Patient's Name" />
      </Grid.Col>
      <Grid.Col span={3}>
        <DatePicker label="Date of Birth" />
      </Grid.Col>
      <Grid.Col />
      <Grid.Col span={4}>
        <TextInput label="Parent/Guardian name (Please Print)" />
      </Grid.Col>
      <Grid.Col span={4}>
        <TextInput label="Patient/Parent/Guardian Signature" />
      </Grid.Col>
    </Grid>
  )
}
export default HippaForm
