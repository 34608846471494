import { Button, Grid, TextInput, Title, Text } from "@mantine/core"
import { DatePicker } from "@mantine/dates"
import { useForm } from "@mantine/form"
import axios from "axios"
import {
  TFinancialLiabilityForm,
  FormType,
  marshalFinancialLiabilityForm,
} from "healthy-smile-forms"
import { useTokenActions } from "../../context/Tokens"

const FinancialLiabilityForm = () => {
  const form = useForm<TFinancialLiabilityForm>({
    initialValues: {
      name: "",
      date: new Date(),
      dateOfBirth: new Date(),
      signature: "",
      type: FormType.FinancialLiabilityForm,
    },
  })
  const { tokens } = useTokenActions()

  const handleSubmit = async (values: TFinancialLiabilityForm) => {
    const formJSON = JSON.stringify(marshalFinancialLiabilityForm(values))
    await axios({
      method: "post",
      url: "https://2ka9dggekf.execute-api.us-east-1.amazonaws.com/prod/email-patient-screening-results",
      headers: {
        Authorization: tokens?.idToken,
      },
      data: formJSON,
    })
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Grid>
        <Grid.Col>
          <Title order={3}>Patient Financial Liability Form</Title>
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput label="Name" {...form.getInputProps("name")} />
        </Grid.Col>
        <Grid.Col span={3} offset={5}>
          <DatePicker label="Date" {...form.getInputProps("date")} />
        </Grid.Col>
        <Grid.Col>
          <Text>
            We are happy you have joined our dental family. We look forward to
            providing quality dental care to you, but before we can proceed, we
            need you to agree to the following terms:
          </Text>
        </Grid.Col>
        <Grid.Col>
          <Text>
            Please understand that full payment of your account is considered
            part of your treatment and is required for all services rendered.
            Also, payment for past services rendered and treatment given is
            required before all future services and treatment may be made. We
            expect full payment at the time the services are rendered. This
            office accepts Visa, Master Card and Discover Card. Checks are
            accepted with a valid photo ID, but returned checks are subject to
            additional service fees. Extended payment plans may be offered with
            PRIOR credit approval but must be made prior to treatment. All
            unpaid accounts are sent to collection after payment is not made in
            a reasonable time period and may adversely affect your credit. You
            agree to pay all fees incurred in the pursuit of delinquent account
            balances. Please understand that non‐emergency services can be
            denied for delinquent accounts and collection action may affect your
            patient status with this practice.
          </Text>
        </Grid.Col>
        <Grid.Col>
          <Title order={5} underline={true}>
            INSURANCE IS ACCEPTED UNDER THE FOLLOWING CONDITIONS:
          </Title>
        </Grid.Col>
        <Grid.Col>
          <Text>
            All co‐payments are due to our office at the time of services.
            Patient agrees to pay all deductibles, coinsurance, and services
            deemed "patient responsibility" as identified by the insurance
            carrier. Deductibles, coinsurance and patient portions are billed
            monthly on receipt of the patient's insurance statement from the
            carrier regarding the patient claim. YOU, the patient, are
            responsible to render payment once billed for the remainder due for
            treatment, should there be a balance after the payments made at time
            of services and the insurance benefit. Claim payments denied by the
            insurance carrier for any reason become the responsibility of the
            patient and you agree not to withhold payment from the Practice in
            the event of a dispute between you and your carrier.
          </Text>
        </Grid.Col>
        <Grid.Col>
          <Text>
            Although we make every effort to obtain accurate information from
            the insurance carrier, verification of benefits is not a guarantee
            that an insurance carrier will pay a claim, or pay the amount
            estimated. Patients are responsible for checking their benefits
            prior to treatment. The insurance carrier makes final determination,
            based upon the plan'slevel of coverage and associated policies, upon
            receiving the claim. Denied claims become the responsibility of the
            patient.
          </Text>
        </Grid.Col>
        <Grid.Col>
          <Text>
            In the event that a patient does not have insurance and is paying by
            cash, we offer a 5% discount off of our billable amount.
          </Text>
        </Grid.Col>
        <Grid.Col>
          <Title order={4} underline={true}>
            CANCELLATION POLICY
          </Title>
        </Grid.Col>
        <Grid.Col>
          <Text>
            If you are unable to keep an appointment, we ask that you kindly
            provide us with us a minimum of 1 business days' notice. (24 hours)
            This change must be made during our normal business hours. This
            courtesy on your part will make it possible to give your appointment
            to another patient who needs to see the dentist or hygienist. To
            maintain optimum appointment availabilities for all patients, we
            must charge a fee of $25 per hour for appointments cancelled with
            less than 24 hours' notice.
          </Text>
        </Grid.Col>
        <Grid.Col>
          <Text>
            I have read the above information and agree to all the terms and
            conditions contained therein
          </Text>
        </Grid.Col>
        <Grid.Col span={3}>
          <DatePicker
            label="Date of Birth"
            {...form.getInputProps("dateOfBirth")}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput label="Signature" {...form.getInputProps("signature")} />
        </Grid.Col>
        <Grid.Col>
          <Button type="submit">Submit</Button>
        </Grid.Col>
      </Grid>
    </form>
  )
}

export default FinancialLiabilityForm
