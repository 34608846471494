import {
  Button,
  Grid,
  List,
  LoadingOverlay,
  ScrollArea,
  Text,
  TextInput,
  TextProps,
  Title,
} from "@mantine/core"
import { DatePicker } from "@mantine/dates"
import { useForm } from "@mantine/form"
import { FormType, TConsentForm } from "healthy-smile-forms"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import useFormApi from "./FormApi"

const MyText = (props: TextProps) => {
  return (
    <Text
      style={{
        lineHeight: 2,
        paddingBottom: 15,
      }}
      {...props}
    >
      {props.children}
    </Text>
  )
}

const ConsentForm = () => {
  const { sendEmail } = useFormApi()
  const [submitting, setSubmitting] = useState(false)
  const navigate = useNavigate()
  const form = useForm<TConsentForm>({
    initialValues: {
      name: "",
      dateOfBirth: new Date(),
      date: new Date(),
      signature: "",
      type: FormType.ConscentForm,
    },
  })

  const handleSubmit = async (values: TConsentForm) => {
    setSubmitting(true)
    await new Promise((resolve) => setTimeout(resolve, 1000))
    setSubmitting(false)
    sendEmail(values)
    navigate("/forms")
  }

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid>
          <Grid.Col span={4}>
            <TextInput label="Name" {...form.getInputProps("name")} />
          </Grid.Col>
          <Grid.Col span={3}>
            <DatePicker
              label="Date of Birth"
              {...form.getInputProps("dateOfBirth")}
            />
          </Grid.Col>
          <Grid.Col>
            <ScrollArea style={{ height: 400 }}>
              <div style={{ width: "80%" }}>
                <Title order={3}>GENERAL CONSENT</Title>
                <MyText>
                  You, the patient, have the right to accept or reject dental
                  treatment recommended by your dentist. Prior to consenting to
                  treatment, you should carefully consider the anticipated
                  benefits and commonly known risks of the recommended
                  procedure, alternative treatments, or the option of no
                  treatment.
                </MyText>
                <MyText>
                  Do not consent to treatment unless and until you discuss
                  potential benefits, risks and complications with your dentist
                  and all of your questions are answered. By consenting to the
                  treatment, you are acknowledging your willingness to accept
                  known risks and complications, no matter how slight the
                  probability of occurrence.
                </MyText>
                <MyText>
                  It is very important that you provide your dentist with
                  accurate information before, during and after treatment. It is
                  equally important that you follow your dentist’s advice and
                  recommendations regarding medication, pre and post treatment
                  instructions, referrals to other dentists or specialists and
                  return for scheduled appointments. If you fail to follow the
                  advice of your dentist, you may increase the chances of a poor
                  outcome.
                </MyText>
                <List>
                  <List.Item>
                    <MyText>
                      I hereby authorize and direct the dentist and/or dental
                      treatment with the use of any necessary or advisable
                      radiographs (x‐rays) and/or any other diagnostic aids in
                      order to complete a thorough diagnosis and treatment plan.
                    </MyText>
                  </List.Item>
                  <List.Item>
                    <MyText>
                      I understand x‐rays, photographs, models of the mouth,
                      and/or other diagnostic aids used for an accurate
                      diagnosis and treatment planning are the property of the
                      doctors but copies of certain aids are available upon
                      request for a fee. I hereby authorize my dentist and
                      his/her staff to take photographs, slides, and/or videos
                      of my face, jaws, mouth, and teeth. I understand that the
                      photographs, slides, and/or videos will be used as a
                      record of my care, and may be used for educational
                      purposes in study club meetings, lectures, seminars,
                      demonstrations, and professional publications (journals,
                      magazines). I further understand that if the photographs,
                      slides, and/or videos are used in any publication or as a
                      part of a demonstration, my name or other identifying
                      information will be kept confidential.
                    </MyText>
                  </List.Item>
                  <List.Item>
                    <MyText>
                      In general terms, the dental procedures can include but
                      not limited to:
                    </MyText>
                    <List>
                      <List.Item>
                        <MyText>
                          Comprehensive oral examination, radiographs, cleaning
                          of the teeth, and topical fluoride application.
                        </MyText>
                      </List.Item>
                      <List.Item>
                        <MyText>
                          Application of resin “sealants” to the grooves of the
                          teeth.
                        </MyText>
                      </List.Item>
                      <List.Item>
                        <MyText>
                          Treatment of diseased, or injured teeth with dental
                          restorations (fillings).
                        </MyText>
                      </List.Item>
                      <List.Item>
                        <MyText>
                          Treatment of diseased or injured oral tissue secondary
                          to traumatic injuries and/or accidents and/or
                          infections.
                        </MyText>
                      </List.Item>
                      <List.Item>
                        <MyText>
                          I understand that the doctor is not responsible for
                          previous dental treatment performed in other offices.
                          I understand that, in the course of treatment, this
                          previously existing dentistry may need adjustment
                          and/or replacement. I realize that guarantees of
                          results or absolute satisfaction are not always
                          possible in dental health service.
                        </MyText>
                      </List.Item>
                      <List.Item>
                        <MyText>
                          I have answered all the questions about me or my
                          dependent’s medical history and present health
                          conditions, including allergies. I also understand if
                          my dependent or I ever have any changes in health
                          status or any changes in medication(s). I will inform
                          the doctor at the next appointment.
                        </MyText>
                      </List.Item>
                      <List.Item>
                        <MyText>
                          I understand that antibiotics, analgesics and other
                          medications can cause allergic reactions causing
                          redness and swelling of tissues, pain, itching,
                          vomiting and/or anaphylactic shock (severe allergic
                          reaction).
                        </MyText>
                      </List.Item>
                      <List.Item>
                        <MyText>
                          I understand that during treatment it may be necessary
                          to change or add procedures because of conditions
                          found while working on the teeth that were not
                          discovered during examination.
                        </MyText>
                      </List.Item>
                      <List.Item>
                        <MyText>
                          I certify that if I, and/or my dependents have
                          insurance coverage I assign directly to the dentist
                          all insurance benefits for services rendered. I
                          understand that I am financially responsible for all
                          charges whether or not paid by insurance. I authorize
                          the use of my signature on all insurance submissions.
                        </MyText>
                      </List.Item>
                    </List>
                  </List.Item>
                </List>
                <MyText>
                  I understand that dentistry is not an exact science, and
                  therefore, reputable practitioners cannot properly guarantee
                  results. I acknowledge that no guarantee or assurance has been
                  made by anyone regarding the dental treatment which I have
                  requested and authorized.
                </MyText>
                <MyText>
                  In addition, my dentist or their staff has given me a more
                  detailed explanation of these treatments and procedures — if I
                  so desired. I am fully satisfied with the description and
                  information given, and all my questions and concerns have been
                  satisfactorily answered. I acknowledge that no guarantee or
                  warranty has been made to me about the results of any of the
                  above choices. Therefore, I freely give my informed consent to
                  the above treatments and procedures, should they be
                  recommended. I further understand that this consent shall
                  remain in effect until terminated by me.
                </MyText>
              </div>
            </ScrollArea>
          </Grid.Col>
          <Grid.Col span={4}>
            <TextInput label="Signature" {...form.getInputProps("signature")} />
          </Grid.Col>
          <Grid.Col span={3}>
            <DatePicker label="Date" {...form.getInputProps("date")} />
          </Grid.Col>
          <Grid.Col>
            <Button type="submit">
              <LoadingOverlay visible={submitting} />
              Submit
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </>
  )
}

export default ConsentForm
