interface INavbarItem {
  path: string
  label: string
}
const navbarItems: INavbarItem[] = [
  {
    path: "/",
    label: "Dashboard",
  },
  {
    path: "/forms",
    label: "Forms",
  },
  {
    path: "/profile",
    label: "Profile",
  },
  {
    path: "/payments",
    label: "Payments",
  },
]

export { navbarItems }
