import { Navbar, UnstyledButton } from "@mantine/core"
import { useLocation, useNavigate } from "react-router-dom"
import { navbarItems } from "../config/navbar-items"

const DesktopNavbar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  return (
    <Navbar
      width={{
        sm: 300,
        lg: 400,
        base: 100,
      }}
      height={500}
    >
      <Navbar.Section>
        {navbarItems.map(({ label, path }) => {
          return (
            <UnstyledButton
              key={label}
              sx={(theme) => ({
                display: "block",
                width: "100%",
                padding: theme.spacing.xs,
                borderRadius: theme.radius.sm,
                "&:hover": {
                  backgroundColor: theme.colors.gray[3],
                },
                backgroundColor:
                  location.pathname === path
                    ? theme.colors.gray[2]
                    : "transparent",
              })}
              onClick={() => navigate(path)}
            >
              {label}
            </UnstyledButton>
          )
        })}
      </Navbar.Section>
    </Navbar>
  )
}
export default DesktopNavbar
