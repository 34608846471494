const scopes = ["email", "openid", "profile", "phone"]
const domain = "patient-portal-healthy-smile.auth.us-east-1.amazoncognito.com"
const loginPath = "/oauth2/authorize"
const logoutPath = "/logout"
const protocol = "https"
const redirectUri = window.location.origin
const responseType = "token"
const clientId = "5j8m0rk927muj9r2p6h6sudjp0"
const loginParams = `?client_id=${clientId}&response_type=${responseType}&scope=${scopes.join(
  "+"
)}&redirect_uri=${redirectUri}`
const logoutParams = `?client_id=${clientId}&logout_uri=${redirectUri}`

export const cognitoLoginUrl = `${protocol}://${domain}${loginPath}${loginParams}`
export const cognitoLogoutUrl = `${protocol}://${domain}${logoutPath}${logoutParams}`
