import { AppShell } from "@mantine/core"
import { useCallback, useState } from "react"
import { useIsLargeScreen } from "../hooks/screenSize"
import CustomHeader from "./CustomHeader"
import DesktopNavbar from "./DesktopNavbar"
import MobileNavbar from "./MobileNavbar"

interface ILayoutProps {
  children: React.ReactNode
}
const Layout = ({ children }: ILayoutProps) => {
  const isLargeScreen = useIsLargeScreen()
  const [drawerOpened, setDrawerOpened] = useState(false)
  const openDrawer = useCallback(() => setDrawerOpened(true), [])
  const closeDrawer = useCallback(() => setDrawerOpened(false), [])
  return (
    <>
      <AppShell
        header={<CustomHeader openDrawer={openDrawer} />}
        navbar={
          isLargeScreen === true ? (
            <DesktopNavbar />
          ) : (
            <MobileNavbar
              drawerOpened={drawerOpened}
              closeDrawer={closeDrawer}
            />
          )
        }
      >
        {children}
      </AppShell>
    </>
  )
}
export default Layout
