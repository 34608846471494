import { Alert } from "@mantine/core"
import { IconAlertCircle } from "@tabler/icons"

const Dashboard = () => {
  return (
    <Alert
      icon={<IconAlertCircle size={16} />}
      title="Under Construction"
      color="blue"
    >
      The dashboard will contain a list of actions that Healthy Smile would like
      you to complete. For example, filling out the patient screening form or
      setting up a payment method.
    </Alert>
  )
}
export default Dashboard
