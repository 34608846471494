import { Button, Card, Grid, Group, Text } from "@mantine/core"
import { useNavigate } from "react-router-dom"

interface IFormItem {
  name: string
  path: string
}

const FormListItem = ({ name, path }: IFormItem) => {
  const navigate = useNavigate()
  return (
    <Grid.Col span="content">
      <Card withBorder>
        <Group>
          <Text weight={500}>{name}</Text>
        </Group>
        <Button
          mt="md"
          variant="light"
          color="blue"
          fullWidth
          onClick={() => navigate(`/forms/${path}`)}
        >
          Open
        </Button>
      </Card>
    </Grid.Col>
  )
}

export default FormListItem
export type { IFormItem }
