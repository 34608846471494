import { useMemo } from "react"
import { useTokenActions } from "../context/Tokens"
import { Alert, Group, Text } from "@mantine/core"
import { IconAlertCircle } from "@tabler/icons"

function parseJwt(token: string) {
  const base64Url = token.split(".")[1]
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join("")
  )

  return JSON.parse(jsonPayload)
}

const Profile = () => {
  const { tokens } = useTokenActions()
  const email = useMemo(() => {
    return parseJwt(tokens.idToken).email
  }, [tokens])

  return (
    <>
      <Group mb="lg">
        <Text weight={500}>Email:</Text>
        <Text>{email}</Text>
      </Group>
      <Alert
        icon={<IconAlertCircle size={16} />}
        title="Under Construction"
        color="blue"
      >
        You will have the option to save set preferences on email and phone
        notifications here. Also, to save time when filling out forms, you will
        have to option to save data such as patient name, birthday, etc.
      </Alert>
    </>
  )
}
export default Profile
