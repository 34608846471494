import axios from "axios"
import {
  FormType,
  marshalConsentForm,
  marshalFinancialLiabilityForm,
  TConsentForm,
  TForm,
} from "healthy-smile-forms"
import { useCallback } from "react"
import { useTokenActions } from "../../context/Tokens"

const useFormApi = () => {
  const { tokens } = useTokenActions()
  const sendEmail = useCallback(
    async (formData: TForm) => {
      const marshalFunction =
        formData.type === FormType.ConscentForm
          ? marshalConsentForm
          : formData.type === FormType.FinancialLiabilityForm
          ? marshalFinancialLiabilityForm
          : () => {
              throw new Error("Invalid form type")
            }
      const url =
        "https://2ka9dggekf.execute-api.us-east-1.amazonaws.com/prod/email-patient-screening-results"
      try {
        await axios({
          method: "POST",
          url,
          headers: {
            Authorization: tokens?.idToken,
          },
          data: marshalFunction(formData),
        })
      } catch (e) {
      } finally {
      }
    },
    [tokens]
  )
  return { sendEmail }
}

export default useFormApi
